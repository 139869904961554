<template>
  <div class="group-signup-container col-12" v-if="!loading">
    <div class="container-body closed" v-if="!eventData.isRegistrationOpen">
      Registration Closed
      <p v-if="eventData.registrationOpenAt != null">
        Opens {{ formattedRegistrationOpenDate(eventData.registrationOpenAt) }}
      </p>
    </div>
    <div class="container-body" v-else>
      <ul class="summary">
        <li class="max-spots">
          <SpotsLeft color="grey" />
          <p>
            <strong>{{ eventData.maxSpots }}</strong
            ><span> Max {{ eventData.maxSpots == 1 ? "spot" : "spots" }}</span>
          </p>
        </li>
        <li class="available-spots">
          <SpotsLeft />
          <p>
            <strong>{{ eventData.maxSpots - takenSpots }}</strong>
            <span>
              Available
              {{
                eventData.maxSpots - takenSpots == 1 ? "spot" : "spots"
              }}</span
            >
          </p>
        </li>
        <li class="confirmed-players">
          <ConfirmedPlayers />
          <p>
            <strong>{{ takenSpots }}</strong>
            <span> Confirmed {{ takenSpots == 1 ? "player" : "players" }}</span>
          </p>
        </li>
        <li class="waiting-players">
          <WaitingList />
          <p>
            <strong>{{ waitingListLength }}</strong
            ><span>
              Waiting {{ waitingListLength == 1 ? "player" : "players" }}</span
            >
          </p>
        </li>
        <li class="wildcards-left">
          <Wildcard />
          <p>
            <strong>{{ wildCardsLeft }} </strong><span>Wildcards left</span>
          </p>
        </li>
      </ul>
      <div
        class="headline"
        v-if="userRegisteredToThisEvent == null && availableSpots > 0"
      >
        <h3>Choose a group</h3>
      </div>
      <div class="headline" v-else-if="userRegisteredToThisEvent !== null">
        <h3>You're currently in group {{ usersGroupNumber + 1 }}</h3>
        <p>Choose another group if you wish to change</p>
      </div>
      <div
        class="headline"
        v-else-if="userRegisteredToThisEvent == null && availableSpots == 0"
      >
        <h3>No spots currently available</h3>
      </div>
      <template v-for="(pool, poolIndex) in groupsData">
        <div class="pool" :key="pool.id" :class="{ bordered: poolIndex > 0 }">
          <h4
            class="pool-title"
            :key="'title' + poolIndex"
            v-if="groupsData.length > 1"
          >
            Pool {{ poolLetters[poolIndex] }}
          </h4>
          <div
            class="group-container"
            @click="reserveSpot(group)"
            v-for="(group, groupIndex) in pool.groups"
            :key="groupIndex"
            :class="{ available: emptySpotsInGroup(group) > 0 }"
          >
            <div class="header">
              <span>Hole {{ holeDisplayName(group.startHole) }}</span>
              <span class="start-time" v-if="group.startsAt != null">
                {{ formattedGroupStartTime(group.startsAt) }}
              </span>
            </div>

            <div class="body">
              <div
                class="player"
                v-for="player in group.playerConnections"
                :key="player.id"
              >
                <div class="division">
                  <span>{{ player.player.division.type }}</span>
                </div>

                <div class="name">
                  {{ player.player.user.firstName }}

                  <span>
                    {{ player.player.user.lastName }}
                  </span>
                </div>
              </div>
              <div
                class="player"
                v-for="player in reservedSpotsInGroup(group)"
                :key="player.id"
              >
                <div class="division">
                  <span></span>
                </div>

                <div class="name">
                  {{ player.user.firstName }}
                  <span>{{ player.user.lastName }}</span>
                </div>
              </div>
              <div
                class="player available"
                v-for="(emptySpot, index) in emptySpotsInGroup(group)"
                :key="index"
              >
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <sweet-modal
      class="left-align"
      :enable-mobile-fullscreen="false"
      blocking
      ref="tourpassregmessage"
      hide-close-button
    >
      <h3 class="registrationmessage-title">Register</h3>
      <div
        class="registrationmessage-subtitle"
        @click="showRegistrationMessageInput = !showRegistrationMessageInput"
      >
        <p><b>Add a note</b> (optional)</p>
        <Chevron2 :direction="showRegistrationMessageInput ? 'up' : 'down'" />
      </div>
      <slide-up-down :active="showRegistrationMessageInput" :duration="200">
        <textarea
          rows="4"
          maxlength="250"
          class="user-reg-message"
          v-model="userRegistrationMessage"
        />
      </slide-up-down>
      <div class="buttons">
        <StandardBorderedButton
          title="Cancel"
          :fluid="false"
          @click="$refs.tourpassregmessage.close()"
        />
        <StandardSolidButton
          title="Register"
          :fluid="false"
          @click="tourPassRegistration(userRegistrationMessage)"
        />
      </div>
    </sweet-modal>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="changeGroup"
      hide-close-button
    >
      <h3>Change group</h3>
      <p>Are you sure you want to change group?</p>

      <div class="buttons">
        <StandardSolidButton
          title="Not now"
          :fluid="false"
          @click="$refs.changeGroup.close()"
        />
        <StandardBorderedButton
          class="forwards"
          title="Change group"
          :fluid="false"
          @click="changeGroup()"
        />
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import moment from "moment";
import Wildcard from "@/assets/icons/Wildcard";
import WaitingList from "@/assets/icons/WaitingList";
import ConfirmedPlayers from "@/assets/icons/ConfirmedPlayers";
import SpotsLeft from "@/assets/icons/SpotsLeft";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import Chevron2 from "@/assets/icons/Chevron2";
import SlideUpDown from "vue-slide-up-down";

export default {
  name: "GroupRegistration",
  data() {
    return {
      eventData: null,
      players: {},
      groupsData: {},
      loading: true,
      groupRegistrationReservations: [],
      userTourpasses: {},
      clickedGroup: null,
      noPoolsAvailableForRegistration: true,
      eventHasStarted: false,
      showRegistrationMessageInput: false,
      userRegistrationMessage: "",
      tourPassGroup: null,
    };
  },
  components: {
    Wildcard,
    WaitingList,
    ConfirmedPlayers,
    SpotsLeft,
    StandardSolidButton,
    StandardBorderedButton,
    SlideUpDown,
    Chevron2,
  },
  watch: {
    $route: function () {
      this.loadData();
    },
    userHasReservedSpot: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal == true) {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Click your spot again to finalise registration.",
            type: "error",
            timer: 5000,
          });
        }
      },
    },
    userRegisteredToThisEvent: function () {
      this.loadData();
    },
  },
  computed: {
    waitingListLength() {
      const registrations = this.eventData.registrations;
      let waitingListLength = 0;

      waitingListLength = registrations.filter(
        (registration) => registration.status == "PENDING"
      ).length;

      return waitingListLength;
    },
    wildCardsLeft() {
      const players = this.eventData.players;
      const maxWildcards = this.eventData.maxWildcards;
      let wildCardsLeft = 0;
      let wildCardOunter = 0;

      players.forEach((player) => {
        if (player.isWildcard) {
          wildCardOunter++;
        }
      });

      if (maxWildcards - wildCardOunter > 0) {
        wildCardsLeft = maxWildcards - wildCardOunter;
      }

      return wildCardsLeft;
    },
    poolLetters() {
      return this.$store.state.poolConversion;
    },
    isUserLoggedIn() {
      return this.$store.getters.isUserLoggedIn;
    },
    availableSpots() {
      return (
        this.eventData.maxSpots -
        (this.groupRegistrationReservations.length + this.players.length)
      );
    },
    takenSpots() {
      return this.groupRegistrationReservations.length + this.players.length;
    },
    usersGroupNumber() {
      const userId = this.$store.getters.userInfo.sessionInfo.userId;
      let usersGroupNumber = null;

      this.groupsData.forEach((pool) => {
        pool.groups.forEach((group) => {
          group.playerConnections.forEach((player) => {
            if (player.player.userId == userId) {
              usersGroupNumber = group.position;
            }
          });
        });
      });

      return usersGroupNumber;
    },
    usersGroup() {
      if (this.userRegisteredToThisEvent == null) {
        return null;
      } else {
        for (var p = 0; p < this.groupsData.length; p++) {
          for (var g = 0; g < this.groupsData[p].groups.length; g++) {
            if (
              this.groupsData[p].groups[g].playerConnections.filter(
                (player) =>
                  player.player.userId ==
                  this.$store.getters.userInfo.sessionInfo.userId
              ).length == 1
            ) {
              return this.groupsData[p].groups[g].id;
            }
          }
        }
        return null;
      }
    },
    userHasValidTourPass() {
      const tourPasses = this.$store.getters.tourPasses;
      const tourId = this.eventData.tour.id;

      return (
        tourPasses.find(
          (pass) => pass.tour.id == tourId && pass.payment.isRefunded == false
        ) || null
      );
    },
    userHasReservedSpot() {
      let userId = this.$store.getters.userInfo.sessionInfo.userId;
      let bool = false;

      this.groupRegistrationReservations.forEach((reservation) => {
        if (reservation.user.id == userId) bool = true;
      });

      return bool;
    },
    userRegisteredToThisEvent() {
      for (var i = 0; i < this.$store.getters.registrations.length; i++) {
        if (
          this.$store.getters.registrations[i].event.id ==
            this.$router.currentRoute.params.eventId &&
          this.$store.getters.registrations[i].status != "USER_CANCELLED"
        ) {
          return this.$store.getters.registrations[i];
        }
      }
      return null;
    },
  },
  methods: {
    holeDisplayName(hole) {
      if (hole.name != null) {
        return hole.name;
      } else {
        return hole.number;
      }
    },
    formattedGroupStartTime(time) {
      return moment(time).format("HH:mm");
    },
    formattedRegistrationOpenDate(dateTime) {
      return moment(dateTime).format("D MMMM YYYY [at] HH:mm");
    },
    reservedSpotsInGroup(group) {
      return this.groupRegistrationReservations.filter(
        (player) => player.group.id == group.id
      );
    },
    changeGroup() {
      if (this.clickedGroup.id != this.usersGroup) {
        this.$axios({
          headers: {
            Authorization: this.$store.state.user.sessionInfo.token,
          },
          data: {
            query: `
              mutation UpdateRegistration($UpdateRegistrationInput: UpdateRegistrationInput!){
              	UpdateRegistration(registrationId:"${this.userRegisteredToThisEvent.id}",input:$UpdateRegistrationInput,asRole:USER){
                  id
                }
              }
              `,
            variables: {
              UpdateRegistrationInput: {
                groupId: this.clickedGroup.id,
              },
            },
          },
        })
          .then(() => {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Group changed",
              type: "success",
            });
            this.$refs.changeGroup.close();
            this.loadData();
          })
          .catch(() => {});
      }
    },
    async tourPassRegistration(regMessage) {
      let registrationMessage = "";

      if (regMessage) {
        registrationMessage = ` , message: "${regMessage}"`;
      }
      try {
        let registration = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation{
              RegisterToEvent(eventId:"${this.$route.params.eventId}",divisionId:"${this.userHasValidTourPass.division.id}", groupId:"${this.tourPassGroup.id}" ${registrationMessage}){
                ... on Registration{
                  id
                  status
                }
              }
            }
          `,
          },
        });

        this.tourPassGroup = null;

        if (registration.data.data.RegisterToEvent != null) {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "You are now registered",
            type: "success",
          });
          this.$refs.tourpassregmessage.close();
          this.loadData();
          this.$store.dispatch("updateUserEventInformation");
        } else {
          this.$refs.tourpassregmessage.close();
          this.$store.dispatch("showMessage", {
            show: true,
            message: "You are already registered to this event",
            type: "error",
          });
        }
      } catch (err) {
        this.$refs.tourpassregmessage.close();
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Something went wrong",
          type: "error",
        });
      }
    },
    sendToLogin() {
      this.$store.dispatch("setReturnToRouteData", this.$route);
      this.$router.push({
        name: "login",
      });
    },
    async createSpotReservation(group) {
      try {
        let registrationReservation = await this.$axios({
          headers: {
            Authorization: this.$store.state.user.sessionInfo.token,
          },
          data: {
            query: `
              mutation{
              	CreateGroupRegistrationReservation(eventId:"${this.$router.currentRoute.params.eventId}",groupId:"${group.id}"){
                  id
                }
              }
              `,
          },
        });

        if (registrationReservation.data.errors) {
          if (
            queryResult.data.errors[0].errorCode == "NO_AVAILABLE_SPOT_IN_GROUP"
          ) {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "No spot available",
              type: "error",
            });
          } else {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Error",
              type: "error",
            });
          }
          this.loadData();
        } else {
          this.$router.push({
            name: "public-event-checkout",
            params: {
              eventId: this.$router.currentRoute.params.eventId,
              groupId: group.id,
            },
          });
        }
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    reserveSpot(group) {
      if (this.eventData.status == "CLOSED") {
        if (!this.isUserLoggedIn) {
          this.sendToLogin();
        } else if (
          this.userRegisteredToThisEvent == null &&
          this.availableSpots > 0
        ) {
          if (this.userHasValidTourPass) {
            this.tourPassGroup = group;

            if (this.eventData.tour.allowRegistrationMessage) {
              this.$refs.tourpassregmessage.open();
            } else {
              this.tourPassRegistration();
            }
          } else {
            this.createSpotReservation(group);
          }
        } else if (this.userRegisteredToThisEvent != null) {
          this.clickedGroup = group;
          this.$refs.changeGroup.open();
        } else if (this.availableSpots == 0) {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Unable to register. Max spots reached.",
            type: "error",
          });
        }
      }
    },
    emptySpotsInGroup(group) {
      let groupReservations = this.groupRegistrationReservations.filter(
        (reservation) => reservation.group.id == group.id
      );
      if (
        this.eventData.groupSize -
          group.playerConnections.length -
          groupReservations.length <=
        0
      ) {
        return 0;
      } else {
        return (
          this.eventData.groupSize -
          group.playerConnections.length -
          groupReservations.length
        );
      }
    },
    loadData() {
      let meQuery = ``;

      if (this.isUserLoggedIn) {
        meQuery = `
          me{
            tourPasses{
              division{
                id
              }
              tour{
                id
              }
            }
          }
        `;
      }

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {
            ${meQuery}
            event(eventId:"${this.$router.currentRoute.params.eventId}"){
              id
              status
              isRegistrationOpen
              maxSpots
              groupSize
              tour{
                allowRegistrationMessage
                id
              }
              players{
                userId
              }
              registrations{
                id
                status
              }
              rounds{
                pools{
                  status
                  groups{
                    id
                    startsAt
                    position
                    startHole{
                      id
                      number
                      name
                    }
                    playerConnections{
                      id
                      position
                      player{
                        id
                        userId
                        user{
                          firstName
                          lastName
                        }
                        division{
                          id
                          type
                        }
                      }
                    }
                  }
                }
              }
              groupRegistrationReservations{
                id
                expireAt
                group{
                  id
                  status
                  position
                }
                user{
                  id
                  firstName
                  lastName
                }
              }
            }
          }
          `,
        },
      })
        .then((queryResult) => {
          this.eventData = queryResult.data.data.event;
          if (this.isUserLoggedIn) {
            this.userTourpasses = queryResult.data.data.me.tourPasses;
          } else {
            this.userTourpasses = [];
          }

          this.players = queryResult.data.data.event.players;
          this.groupsData = queryResult.data.data.event.rounds[0].pools;
          this.groupRegistrationReservations =
            queryResult.data.data.event.groupRegistrationReservations;
          this.clickedGroup = null;

          this.eventData.rounds.forEach((round, roundIndex) => {
            round.pools.forEach((pool) => {
              if (roundIndex == 0 && pool.status == "CLOSED") {
                this.noPoolsAvailableForRegistration = false;
              }

              if (pool.status == "OPEN" || pool.status == "COMPLETED") {
                this.eventHasStarted = true;
              }
            });
          });

          if (this.eventHasStarted) {
            this.$router.push({ name: "public-event-players" });
          } else if (this.noPoolsAvailableForRegistration) {
            this.$router.push({
              name: "public-event-checkout",
            });
          } else {
            this.loading = false;
          }
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>
<style lang="scss">
.left-align {
  .sweet-modal.is-alert .sweet-content .sweet-content-content {
    text-align: left;
    .registrationmessage-subtitle {
      display: flex;
      align-items: center;
      justify-content: space-between;

      cursor: pointer;
      p {
        font-size: 16px !important;
        margin: 0;
      }
    }

    textarea {
      margin-top: 12px;
      width: 100%;
      border-radius: 6px;
      border: 1px solid $sleet;
      transition: all ease 0.3s;
      padding: 12px 15px;

      &:hover {
        border: 1px solid $midnight;
      }
    }
    .buttons {
      margin-top: 24px !important;
    }
  }
}
@media (min-width: 1200px) {
  .left-align {
    .sweet-modal.is-alert .sweet-content .sweet-content-content {
      .registrationmessage-title {
        margin-bottom: 18px !important;
      }
      .registrationmessage-subtitle {
        font-size: 16px !important;
        display: flex;
      }
      .buttons {
        margin-top: 42px !important;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.group-signup-container {
  margin-top: 32px;
}
.summary {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 16px;
  margin: 0;
  border: 1px solid $fog;
  border-radius: 10px;

  li {
    display: flex;
    align-items: center;
    position: relative;
    svg {
      margin-right: 6px;
      height: 16px;
      width: 16px;
    }
    p {
      line-height: 1rem;
      color: $blizzard;
      margin: 0;
      strong {
        @include Gilroy-Bold;
      }
      span {
        display: none;
      }
    }
    &.max-spots {
      &:hover {
        &::after {
          content: "Max spots";
        }
      }
    }
    &.confirmed-players {
      &:hover {
        &::after {
          content: "Confirmed players";
        }
      }
    }
    &.available-spots {
      &:hover {
        &::after {
          content: "Available spots";
        }
      }
    }
    &.waiting-players {
      &:hover {
        &::after {
          content: "Waiting players";
        }
      }
    }
    &.wildcards-left {
      &:hover {
        &::after {
          content: "Wildcards left";
          left: 50%;
          transform: translateX(-60%);
        }
      }
    }
    &:hover {
      &::after {
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
        background: $midnight;
        height: 28px;
        font-size: 12px;
        white-space: nowrap;
        padding: 0 10px;
        border-radius: 14px;
        color: white;
        display: flex;
        align-items: center;
        @include Gilroy-Bold;
        letter-spacing: 0.2px;
      }
    }
  }
}
.container-body {
  width: 100%;

  &.closed {
    @include Gilroy-Bold;
    font-size: 18px;
    margin-top: 26px;
    text-align: center;
    color: $watermelon;

    p {
      @include Gilroy-Regular;
      font-size: 16px;
      margin-top: 5px;
      text-align: center;
      color: $midnight;
    }
  }

  .pool {
    margin-top: 18px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &.bordered {
      margin: 0;
      border-top: 1px solid $fog;
    }
  }
  .pool-title {
    font-size: 20px;
    margin: 18px 0 18px 0;
    @include Gilroy-Bold;
    width: 100%;
  }
}

.headline {
  margin-top: 32px;
  h3 {
    font-size: 24px;
    margin: 0;
    @include Gilroy-Bold;
    width: 100%;
  }

  p {
    font-size: 14px;
    margin-top: 8px;
  }
}

.group-container {
  width: 47%;
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 32px;
  border: 1px solid white;
  border-radius: 4px;
  transition: all ease 0.3s;

  .start-time {
    font-size: 14px;
    @include Gilroy-Bold;
  }

  &.available {
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 0 1px $ocean, 0 5px 8px 1px rgba(#000, 0.25);
      border: 1px solid $teal;
    }
  }

  &:hover {
    border: 1px solid $watermelon;
  }

  .header {
    background-color: $fog;
    font-size: 14px;
    @include Gilroy-Bold;
    height: 32px;
    justify-content: space-between;
    padding: 0 10px;
    display: flex;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .thin {
      @include Gilroy-Regular;
    }
  }

  .player {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 48px;
    border-bottom: 1px solid $fog;
    border-left: 1px solid $fog;
    border-right: 1px solid $fog;
    transition: all ease 0.3s;

    .name {
      font-size: 12px;
      text-transform: uppercase;
      @include Gilroy-Bold;
      padding-left: 10px;
      margin-right: auto;
      display: flex;
      flex-wrap: wrap;
      max-width: calc(100% - 50px);
      width: 100%;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      overflow: hidden;
      position: relative;
      line-height: 16px;

      span {
        text-transform: none;
        display: flex;
        align-items: center;
        width: 100%;
        @include Gilroy-Regular;
        font-size: 11px;
        line-height: 16px;
      }
    }

    .division {
      background-color: $mist;
      height: 100%;
      width: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      color: $strom;
      span {
        display: flex;
        transform: rotate(-90deg);
        font-size: 9px;
        justify-content: center;
        align-items: center;
        @include Gilroy-Bold;
        letter-spacing: 0.9px;
        text-transform: uppercase;
        color: $blizzard;
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
@media (min-width: 1200px) {
  .group-signup-container {
    margin-top: 48px;
    border-top: 1px solid $fog;
  }
  .headline {
    margin-top: 64px;
    h3 {
      font-size: 32px;
      margin: 0;
      @include Gilroy-Bold;
    }
    p {
      font-size: 18px;
      margin-top: 18px;
    }
  }

  .summary {
    padding: 0;
    border: none;
    border-radius: 0;
    margin-top: 64px;

    li {
      svg {
        margin-right: 10px;
        height: 20px;
        width: 20px;
      }
      p {
        line-height: 1.1rem;
        color: $strom;
        margin: 0;
        font-size: 16px;

        span {
          display: inline;
        }
      }

      &.wildcards-left {
        &:hover {
          &::after {
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  .container-body {
    border-top: 0px solid white;

    &.closed {
      margin-top: 60px;
      font-size: 36px;

      p {
        font-size: 24px;
        color: $midnight;
        @include Gilroy-Regular;
      }
    }

    .pool {
      margin-top: 24px;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: flex-start;

      .pool-title {
        font-size: 24px;
        margin: 32px 0 18px 0;
        @include Gilroy-Bold;
      }
    }
  }
  .group-container {
    width: calc(20% - 28px);
    display: flex;
    flex-direction: column;
    margin-right: 35px;
    margin-bottom: 60px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    &:nth-child(5n) {
      margin-right: 0;
    }

    .header {
      height: 40px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      span {
        font-size: 16px;
      }

      .start-time {
        font-size: 14px;
        @include Gilroy-Bold;
      }
    }

    .player {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 58px;
      border-bottom: 1px solid $fog;
      border-left: 1px solid $fog;
      border-right: 1px solid $fog;
      transition: all ease 0.3s;

      .name {
        font-size: 16px;
        text-transform: uppercase;
        @include Gilroy-Bold;
        padding-left: 10px;
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
        max-width: calc(100% - 50px);
        width: 100%;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        line-height: 16px;
        font-size: 16px;
        line-height: 1.4em;

        span {
          font-size: 14px;
        }
      }

      .division {
        height: 58px;
        width: 23px;
        span {
          font-size: 11px;
        }
      }
    }
  }
}
</style>
